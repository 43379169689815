<template>
  <div class="qna pt-4">
    <h5 class="letter-spacing-2">Questions & Answers</h5>
    <div class="container">
      <div class="row">
        <div class="col-12">
            <div class="mt-5">
              <p class="mb-0">This section will hold most relevant questions and answers</p>
              <p class="mb-0">No questions asked so far</p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QnA'
}
</script>

<style lang="scss">

</style>